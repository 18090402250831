import React, { ReactElement, useRef } from 'react';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import * as Styled from './styles';
import { css } from '@emotion/react';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { breakpoints } from '../../styles/variables';
import Divider from '../../assets/divider.inline.svg';
import { useLocation } from '@reach/router';


export const FantasticReasons = (): ReactElement => {
  const accordionRef = useRef(null);
  const { isVisible } = useVisibilityDetection(accordionRef);
  const location = useLocation(); 
  return (

      <Styled.Wrapper ref={accordionRef} isVisible={isVisible}>
      <Styled.ContainerBlue    css={css`
      @media screen and (max-width: ${breakpoints.md}px) {
        margin-top:0;
      }
      `}
      
      >

        <Styled.Banner>
          <Styled.BannerLeftColumn ><div>Did We Mention How FUN SketchWow <br/> Is to Use?</div></Styled.BannerLeftColumn>
          <Styled.BannerRightColumn >
            <p>Our customers tell us how much they enjoy using SketchWow - compared to all those dated, dull diagram tools from 2008.</p>
            <p>Imagine if... you were excited to make (typically boring) diagrams, maps, journeys, flows, lessons or content. Try SketchWow and see for yourself.</p>
            <p>If all this is starting to sound really good, It gets better...</p>
          </Styled.BannerRightColumn>
        </Styled.Banner>
      </Styled.ContainerBlue>

      <TwoColSection
        styles={css`
          
          max-width: 1170px;
          margin-top: 118px;

          /* opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/

          @media screen and (max-width: ${breakpoints.xl}px) {
            margin-top: 80px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
            padding:0;
          }

          
        `}
        heading={
          <Styled.Heading>
            <Styled.HeadingTitle>Four more fantastic reasons to join SketchWow...</Styled.HeadingTitle>
          </Styled.Heading>
        }
        leftColumn={
          <Styled.LeftColumn>
            
              <Styled.Illustration
                src=  "/salespage/11th section/Install-multiple-computers.png" 
                alt="Illustration"
                loading="lazy"
              />
            <Styled.Content>
              <Styled.SubTitle>Install SketchWow on multiple computers</Styled.SubTitle>
              
              <p>
                It's rare to own just one computer these days. Most software limits you to installing 1-2 computers. Not SketchWow. You can install your SketchWow software on all the PCs and Macs that you own/use.
              </p>
              <p>
                What if your trusty old computer bites-the-dust and you buy a new one? No problem. You can install (or reinstall) SketchWow as many times as you like.
              </p>

            </Styled.Content>
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>
            <Styled.Illustration
                src="/salespage/11th section/Group 4478.svg"
                alt="Illustration"
                loading="lazy"
              />
            <Styled.Content>
            <Styled.SubTitle>Create & Export Your Visuals Using a Variety Of Pre-Defined Sizes & File Formats</Styled.SubTitle>

               <p>
                 Don't be surprised if you start to use SketchWow for everything - your Instagram/Facebook posts, Youtube thumbnails, mockups, presentations, blog
                 post images, one page lesson plans and more.
               </p>
               <p>
                 You can use handy presets or choose a custom size. Then, export your designs in various formats including: jpg, svg, png, transparent background png, pdf or animated gif (animation = even more fun!)
               </p>
            </Styled.Content>
          </Styled.RightColumn>
        }
      />

<TwoColSection
        styles={css`
          
          max-width: 1170px;
          margin-top: 0px;

          /* opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/

          @media screen and (max-width: ${breakpoints.xl}px) {
            padding:0;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
          }

          
        `}
      
        leftColumn={
          <Styled.LeftColumn>
            
              <Styled.Illustration
                src= "/salespage/11th section/Free-software-updates.png" 
                alt="Illustration"
                loading="lazy"
              />
            <Styled.Content>
              <Styled.SubTitle>Includes FREE Software Updates</Styled.SubTitle>
              
              <p>
                We get this question a lot... "Is SketchWow still being actively updated?".
              </p>
              <p>
                You betcha! Some software companies add a new font every three months and call that an update. 
                That's not how we roll. Every few weeks, we release a new update. 90% of these updates are based on feedback
                and suggestions from our valued customers.
              </p>
              <p>
                  Once you join us, don't be shy. Speak up. Let us know what you want. Your feedback is what drives our future features.
              </p>
            </Styled.Content>
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>
            <Styled.Illustration css={css`
                    @media screen and (max-width: ${breakpoints.xl}px) {
                      width:100%;
                    }
                    `}
                src= "/salespage/11th section/30-day-mb-guarantee-removebg-preview.png"
                alt="Illustration"
                loading="lazy"
              />
           <Styled.Content>
                <Styled.SubTitle>30-Day moneyback guarantee</Styled.SubTitle>
                <p>
                  This is pretty typical. Our guarantee is a bit different because...
                </p>
                <p>
                  A. If you are not thrilled, you will get a fast refund. We won't ignore you, pretend that your emails got lost or make you wait 2 weeks.
                </p>

                <p>
                  B. We won't nag you with an "exit survey" with 10 silly questions about why you chose to leave. Bottom line. We will do everything to make sure
                  you are thrilled with SketchWow. If for whatever reason you change your mind, you'll get a full, prompt refund. End of story.
                </p>
                
              </Styled.Content>
          </Styled.RightColumn>
        }
      />

      <Divider/>
      </Styled.Wrapper>

  );
};











