import React, { ReactElement, useRef } from 'react';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import * as Styled from './styles';
import { css } from '@emotion/react';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { breakpoints } from '../../styles/variables'; 
import { FeatureParagraphCrown } from "../../styles/styles";
import LogoIllustration from '../../assets/logo.inline.svg';
import { useLocation } from '@reach/router';

export const Introduction = (): ReactElement => {
  const introductionRef = useRef(null);
  const { isVisible } = useVisibilityDetection(introductionRef);
  const location = useLocation(); 
  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper ref={introductionRef} isVisible={isVisible}>
        <div className='left'>
      <TwoColSection
        styles={css`
          
          max-width: 1170px;
          margin-top: 0px;

          /* opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/

          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
          }

          
        `}

        leftColumn={
          <Styled.LeftColumn>
              <Styled.SubTitle>Introducing 
              <Styled.FeatureParagraphMarkLong><LogoIllustration css={css`
                                              height: 50px;
                                              margin-top: -12px;
                                              position: absolute;
                                              @media screen and (max-width: ${breakpoints.sm}px) {
                                                width:100px;
                                              }

                                                 `}/> </Styled.FeatureParagraphMarkLong>
                </Styled.SubTitle>
              <Styled.Illustration
                src= "/salespage/4th section/laptop.png"
                alt="Illustration"
                loading="lazy"
              />
            
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>
            <Styled.Content>
              <p><strong css={FeatureParagraphCrown}>SketchWow</strong> software offers you the best of both worlds. There is zero freehand drawing or tech skills required. Whenever you need catchy, professional quality designs... no need to hire an expensive illustrator. Just browse through our library of awesome, professionally designed templates and go!</p>

              <p>All these templates are designed to deliver that  <Styled.FeatureParagraphMarkShort>"wow"</Styled.FeatureParagraphMarkShort> factor. The more people read, click and engage with your visuals... that can turn into more leads, followers, sales and to generate more attention and impact. </p>

              <p>Don't just take our word for it. Scroll down to see for yourself how these SketchWow templates really POP...</p>
            </Styled.Content>
          </Styled.RightColumn>
        }
      />


      </div>

      
      <Styled.ContainerYellow    css={css`
      @media screen and (max-width: ${breakpoints.md}px) {
        margin-top:0;
      }
      `}
      
      >

        <Styled.Banner>
          <Styled.BannerLeftColumn >
            <Styled.HeadingHighlight><h2>Powered By AI </h2><Styled.NewHighlight>NEW</Styled.NewHighlight></Styled.HeadingHighlight>
            <br/><br/>
            <p>Since you are reading this... you're  a smart-cookie who loves saving time, right? We gotchu!</p>
            <p>If you've seen other "AI" diagramming tools, you already know how they work. Who wants more basic, boring diagrams... "powered by AI"?</p>
            <p><strong><u>Nobody does.</u></strong></p>
            <p>That's why, here at SketchWow... we took our time to maintain the same level of awesomeness with the unique AI-powered visuals you can create. Oh, and we did something unheard too...</p>
          </Styled.BannerLeftColumn>
          <Styled.BannerRightColumn >
            
          </Styled.BannerRightColumn>
        </Styled.Banner>
      </Styled.ContainerYellow>


      <TwoColSection
        styles={css`
          
          max-width: 1100px;
          margin-top: 50px;
          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
          }

          
        `}

        heading={
          <Styled.Heading>
            <Styled.HeadingTitle
               css={css`
                  font-size: 30px; 
                  font-weight: 600;
              `}>
                Zero Monthly "AI" Subscription Fees!</Styled.HeadingTitle>
          </Styled.Heading>
        }

        leftColumn={
          <Styled.LeftColumn>
              <Styled.Content>
              <p>You already know how those other AI tools work... They trick you with 20 "free" AI credits. Then force you to pay $50+ to buy more - or pay a monthly subscription fee. Not here. We don't charge you any monthly fees to use our AI feature. You get free updates too. </p>
            </Styled.Content>
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>
            <Styled.Content>
              <p>Next time... jumpstart your next big idea, brainstorm, workflow or visual summary using AI!</p>
              <p>Using SketchWow's AI-assisted features, you can create even faster, catchy visuals auto-magically (on-demand), including:</p>
            </Styled.Content>
          </Styled.RightColumn>
        }
      />

        <Styled.Bottom>
          <div>
            <Styled.AITypes>
              <img
                src="/salespage/4th section/flowchart.svg"
                alt="Flowchart"
                 
              />
              <p>Flowcharts</p>
            </Styled.AITypes>  
            <Styled.AITypes>
              <img
                src="/salespage/4th section/mindmaps.svg"
                alt="Mind Maps"
                 
              />
              <p>Mind Maps</p>
            </Styled.AITypes> 
            <Styled.AITypes>
              <img
                src="/salespage/4th section/visual.svg"
                alt="Visual Summaries"
                 
              />
              <p>Visual Summaries</p>
            </Styled.AITypes> 
            <Styled.AITypes>
              <img
                src="/salespage/4th section/AI-Infographics-Icon.svg"
                alt="Infographics"
                 
              />
              <p>Infographics</p>
            </Styled.AITypes> 
            </div>
        </Styled.Bottom>

 

    </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};











