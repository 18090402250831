import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import { Visibility } from "../../types";

const TOP_BACKGROUND_HEIGHT = "1.45vw";
const BOTTOM_BACKGROUND_HEIGHT = "1.88vw";
export const SafariWrapper = styled.div`
  width: 100%;
  overflow: hidden;
`;

export const Wrapper = styled.section<Visibility>`
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  margin-top: ${TOP_BACKGROUND_HEIGHT};
  //margin-bottom: calc(95px + ${BOTTOM_BACKGROUND_HEIGHT});
  //padding: 0 ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;

 
  @media screen and (max-width: ${breakpoints.lg}px) {
    height: auto;
    //margin-bottom: calc(60px + ${BOTTOM_BACKGROUND_HEIGHT});
    margin-bottom:0;
    
  }


  &:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
    height: 120px;
    bottom: -60px;
    background: url('/salespage/2nd section/bottom-yellow-path-coverup.svg') no-repeat center 0;

    ${({ grayBottom }) =>
    grayBottom   &&
    `
      background: url('/salespage/4th section/bottom-beige-path-coverup.svg') no-repeat center 0;
    `}

    background-size: 100% 120px;

    @media screen and (min-width: 2300px) {
      background-size: 100% 140px;
    }
    
    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.1);
    }
  }
`;

export const Heading = styled.header`
  width: 100%;
  margin-bottom: 33px;
text-align:center;
`;

export const HeadingTitle = styled.h2`
  margin: 0;
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};
   margin-bottom: 40px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size:23px;
  }
`;

export const SubTitle = styled.h3`
  margin: 0 0 19px 0;
  /*color: #9d978b;*/
  font-weight: 900;
  font-size: ${dimensions.fontSize.large}px;
  line-height: ${dimensions.lineHeight.large};
`;

export const CheckList = styled.div`
  &:before {
    content: url("/salespage/2nd section/bullet-green-check.svg"); 
    margin-right: 20px;
    @media screen and (max-width: ${breakpoints.md}px) {
        position:absolute;left:0;margin-right:0;top:6px;
    }
  }
  
  font-size: 15px;
  margin-bottom: 10px;
  @media screen and (max-width: ${breakpoints.md}px) {
position:relative;padding-left:40px;
  }
`;

export const LeftColumn = styled.div`
  padding-right: 20px;
`;

export const Content = styled.div`
  color: ${colors.lightGray};
  font-size: ${dimensions.fontSize.medium}px;
  line-height: 1.63;

  p {
    margin: 26px 0;
  }

  @media screen and (max-width: ${breakpoints.xl}px) {
    font-size: ${dimensions.fontSize.medium * 0.9}px;
  }
`;

export const RightColumn = styled.figure`
  @media screen and (max-width: ${breakpoints.md}px) {
    margin: 0;
    padding: 0;
  }
`;

export const Illustration = styled.img`
  transform: translate(21px, -12px);

  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 100%;
    transform: none;
  }
`;

export const PenScribble = styled.div`
  background: url("/salespage/2nd section/pen-scribble.svg") no-repeat; 
  width: 400px;
  height: 100px;
  margin: auto;
  margin-top: 40px;
  @media screen and (max-width: ${breakpoints.sm}px) {
    width: 200px;height:40px;
    background-size:100%;

  }
`;

export const SWFeatures = styled.div`
  // background: url("/salespage/2nd section/SW-versus-everybody.png") no-repeat; 
  // background-position: contain;
  // background-size: contain;
  height: 775px;
  margin: auto;
  margin-top: -120px;
  position:relative;
  max-width: ${({ maxWidth = 1220 }) => maxWidth}px;

  @media screen and (max-width: 1350px) {
    margin-top: 0px;
    height:auto;padding-bottom:50px;
  }
  @media screen and (max-width: ${breakpoints.xl}px) {
    max-width:90%;   
  } 
  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width:85%;   
  } 

  

  &:before {
    content: url("/salespage/2nd section/arrow-down.svg"); 
    margin-left: -60px;
    @media screen and (max-width:1350px) {
      content:'';
      background-image: url("/salespage/2nd section/arrow-down.svg"); 
      background-repeat:no-repeat;width:75px;height:140px;position: absolute;
      left: -45px;top: -80px;background-size: 95%;margin-left:0;
    }

    @media screen and (min-width: 1301px) and (max-width: 1335px) {
      left: -30px;top: -100px;
    }
    @media screen and (min-width: 1201px) and (max-width: 1300px) {
      left: -20px;top: -100px;
    }
    @media screen and (min-width: 1201px) and (max-width: 1279px) {
      left: 0;background-size: 90%;
    }   
    @media screen and (max-width: ${breakpoints.xl}px) {
      background-size: 80%;left:-50px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      background-size:75%;  left: -70px;top: -90px;
    }
    @media screen and (min-width: 769px) and (max-width: 870px) {
      left:-60px;
    }  
    @media screen and (max-width:  ${breakpoints.md}px) {
      display:none;
    }
  }


  div{

    &:before {
      content: url("/salespage/2nd section/side-icons.png"); 
      margin-left: -120px;
      width: 159px;
      position: absolute;
      margin-top: 80px;
      @media screen and (max-width: 1400px) {
        margin-left: -100px;
      }
      @media screen and (max-width:1350px) {
        content:'';
        background-image: url("/salespage/2nd section/side-icons.png"); 
        background-repeat:no-repeat;width:160px;height:430px;
        background-size: 95%;
        margin-left: -90px;
      }
      @media screen and (min-width: 1301px) and (max-width: 1335px) {
        margin-left: -65px;background-size: 90%;width:140px;height:300px;
      }
      @media screen and (min-width: 1201px) and (max-width: 1300px) {
        margin-left: -50px;background-size: 80%;width:140px;height:300px;
      }
      @media screen and (min-width: 1201px) and (max-width: 1279px) {
        margin-left: -80px;
      }

      @media screen and (max-width: ${breakpoints.xl}px) {
        margin-left: -70px;width:140px;background-size: 80%;height:300px;
      }
      
      @media screen and (max-width: ${breakpoints.lg}px) {
        background-size: 75%;width:120px;height:235px;margin-top:90px;
      }
      @media screen and (max-width: ${breakpoints.md}px) {
       margin-left:-65px;margin-top:50px;
        background-size: 75%;
      }
      @media screen and (max-width: 599px) {
        width: 100px;
        height: 150px;
        margin-left: -40px;
        background-size: 50%;margin-top: 80px;
      }
      @media screen and (max-width: 430px) {
        margin-top: 50px;
      }
      @media screen and (max-width:359px) {
        width: 80px; margin-left: -30px;

      }
       
    }

  }
`;

export const ArrowDown = styled.div`
  &:before {
    content: url("/salespage/2nd section/arrow-down.svg"); 
  }

  float: left;
  height: 138px;
  display: absolute;
  margin-top: -60px;

`;