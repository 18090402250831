import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';

 

export const Wrapper = styled.section<Visibility>`
  
  max-width: ${1113 + dimensions.containerPadding * 2}px;
  width: 100%;
  margin: 116px auto 0 auto;
  padding: 0 ${dimensions.containerPadding}px;

  /* opacity: 0;
  transform: translateY(2%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    transform: translateY(0);
  `}*/

  svg {
    width: 100%;
    margin-top: 63px;

    @media screen and (max-width: ${breakpoints.lg}px) {
      margin-top: 40px;
    }
  }

  @media screen and (max-width: ${breakpoints.xl}px) {
    margin-top: 40px;
  }
`;


export const Heading = styled.header`
  width: 100%;
  margin-bottom: 33px;
  text-align: center;
`;

export const HeadingTitle = styled.h2`
  margin: 0;
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size:24px
  }
`;

export const SubTitle = styled.h3`
  margin: 0 0 19px 0;
  /*color: #9d978b;*/
  font-weight: 900;
  font-size: ${dimensions.fontSize.large}px;
  line-height: ${dimensions.lineHeight.large};
`;

export const LeftColumn = styled.div`
  padding-right: 20px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    text-align:center;
    padding-right: 0px;
  }
`;

export const Content = styled.div`
  color: ${colors.lightGray};
  font-size: ${dimensions.fontSize.medium}px;
  line-height: 1.63;
  padding-right:10px;

  p {
    margin: 26px 0;
  }

  @media screen and (max-width: ${breakpoints.xl}px) {
    font-size: ${dimensions.fontSize.medium * 0.9}px;
  }
`;

export const RightColumn = styled.figure`
@media screen and (max-width: ${breakpoints.lg}px) {
  text-align:center;
}
  @media screen and (max-width: ${breakpoints.md}px) {
    margin: 0;
    padding: 0;
  }
`;

export const Illustration = styled.img`
  transform: translate(21px, -12px);

  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 100%;
    transform: none;
  }
`;

export const ContainerBlue = styled.div`
  width: 800px;
  margin: auto;
  background-color: #0a2460;
 
  height: 300px;
 

  border-radius: 5px;
  color:#fff;
  padding: 30px ;
  padding-bottom: 0px;
  margin-top: 70px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    width:100%;
    height: auto;
    padding:30px 15px;
  }

 
`;

export const Banner = styled.div`
    display: grid;
    grid-template-columns: 0.5fr 1fr;
    align-items: center;
    @media screen and (max-width: ${breakpoints.md}px) {
      grid-template-columns: 1fr;
    }
`;

export const BannerLeftColumn = styled.div`
  padding-right: 20px;
  background: url("/salespage/11th section/background-img.png") no-repeat left bottom;
  background-size: 200px;
 
  background-size: 250px;
  height: 250px;
 


  

  @media screen and (max-width: ${breakpoints.md}px) {
    margin-bottom:30px;
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    //font-size: ${dimensions.fontSize.heading * 0.7}px;
    font-size:18px;
  }
  div{
    font-size: 26px;
    color: gold;
    font-weight: 400;
  }
`;

export const BannerRightColumn = styled.div`
  margin-top: -10px;
 
  font-size: ${dimensions.fontSize.medium}px;
 
  p{
    margin-bottom: 25px;
  }
`;