import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import { Visibility } from "../../types";

const TOP_BACKGROUND_HEIGHT = "1.45vw";
const BOTTOM_BACKGROUND_HEIGHT = "1.88vw";

 

export const SafariWrapper = styled.div`
  width: 100%;
  overflow: hidden;

`;

export const Wrapper = styled.section<Visibility>`
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  margin-top: ${TOP_BACKGROUND_HEIGHT};
  // margin-bottom: calc(95px + ${BOTTOM_BACKGROUND_HEIGHT});
  // padding: 0 ${dimensions.containerPadding}px;
  position: relative;

  
  @media screen and (max-width: ${breakpoints.lg}px) {
    height: auto;
    //margin-bottom: calc(60px + ${BOTTOM_BACKGROUND_HEIGHT});
  }

  @media screen and (max-width: ${breakpoints.md}px) {
      margin-bottom:0;
  }

  // &:after {
  //   content: "";
  //   width: 100%;
  //   position: absolute;
  //   left: 0;
  //   background-size: contain;
  //   height: 200px;
  //   bottom: -130px;
  //   background: url('/salespage/4th section/bottom-beige-path-coverup.svg') no-repeat center 0;
  //   background-size: 100% 100px;

  //   @media screen and (max-width: ${breakpoints.lg}px) {
  //     transform: scale(1.1);
  //   }
  // }
`;

export const Section = styled.section`

  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  position: relative;
 
  align-items: center;
  box-sizing: border-box;
 

  @media screen and (min-width: 1024px) and (max-width: ${breakpoints.lg}px) {
    padding-top: 80px;
    padding-bottom: 80px;

  }

  @media screen and (max-width: ${breakpoints.md}px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }


`;

export const Content = styled.div`
  padding-bottom: 6px;
  position: relative;
  z-index: 4;

  @media screen and (max-width: ${breakpoints.lg}px) {

    margin-top: 40px;

    align-items: center;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    max-width: 100%;
  }
`;


export const Heading = styled.header`
  width: 100%;
  //margin-bottom: 33px;
  text-align: center;
`;

export const HeadingTitle = styled.h2`
  margin: 0;
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size:24px;
  }
`;

export const SubTitle = styled.h3`

  /*color: #9d978b;*/
  font-weight: 300;
 
    font-size: ${dimensions.fontSize.medium}px;
 

  
  line-height: ${dimensions.lineHeight.large};
  //position: absolute;
  text-align: center;
  z-index: 2;
  @media screen and (max-width: ${breakpoints.lg}px) {
    position: inherit;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    font-size:18px;
  }
`;

export const CheckList = styled.span`
  &:before {
    content: url("/salespage/2nd section/bullet-green-check.svg"); 
    margin-right: 20px
  }
  
  font-size: 15px;
`;

export const LeftColumn = styled.div`
  // padding-right: 20px;
  // margin-left: -100px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    text-align:center;
  }
`;


export const RightColumn = styled.div`
  margin-left: 0px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    text-align:center;
  }
`;

export const Illustration = styled.img`
  transform: translate(21px, -12px);
  width:550px;
  margin-top: 80px;
  @media screen and (max-width: ${breakpoints.xl}px) {
    width:350px;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    max-width: 80%;
    transform: none;
    width:550px;
    margin-top: 0px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    width:100%;   
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
  max-width: 100%;
  }
`;

export const IconContainer = styled.div`
  padding: 20px;
  display: flex;
  @media screen and (max-width: ${breakpoints.md}px) {
    padding: 20px 0;
    flex-wrap:wrap;
    gap:1.5rem;
    justify-content:center;align-items:center;
  }
`;

export const Icons = styled.div`
  margin-left: 30px;

  float: left;
  text-align: center;
  vertical-align: middle;
  small{
    white-space: nowrap;
    margin-bottom: 0px;
  }
  img{
    text-align: center;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    margin-left: 0px;

  }
`;

export const FeatureParagraphMarkLong = styled.span`
  position: relative;

  &:before {
    content: '';
    width: 180px;
    height: 12px;
    position: absolute;
    bottom: -13px;
    right: -250px;
    z-index: -1;
    background: url('/salespage/4th section/long-blue-underline.svg') no-repeat 0 0;
    background-size: contain;

    @media screen and (max-width: 414px) {
      right: 238px;
    }

    @media screen and (max-width: 400px) {
      right: 48px;
    }

    @media screen and (max-width: 280px) {
      right: 108px;
    }
  }
  // &:after {
  //   content: '';
  //   width: 34px;
  //   height: 17px;
  //   position: absolute;
  //   bottom: -14px;
  //   left: 0;
  //   z-index: -1;
  //   background: url('/salespage/4th section/long-blue-underline.svg') no-repeat 0 0;
  //   background-size: contain;

  //   @media screen and (max-width: 414px) {
  //     left: -290px;
  //   }
  //   @media screen and (max-width: 400px) {
  //     left: 0px;
  //   }
  //   @media screen and (max-width: 280px) {
  //     left: -170px;
  //   }
  // }
`;

export const FeatureParagraphMarkShort = styled.span`
  position: relative;


  &:after {
    content: '';
    width: 34px;
    height: 17px;
    position: absolute;
    bottom: -14px;
    left: 10px;
    z-index: -1;
    background: url('/salespage/4th section/short-blue-underline.svg') no-repeat 0 0;
    background-size: contain;


    @media screen and (max-width: 414px) {
      left: -290px;
    }
    @media screen and (max-width: 400px) {
      left: 0px;
    }
    @media screen and (max-width: 280px) {
      left: -170px;
    }
  }
`;