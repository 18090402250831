import React, { ReactElement, useRef, useEffect, useState } from 'react';
import { css } from '@emotion/react';
import * as Styled from './stylesv2';

import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { SpellLike } from '../../components/SpellLike/SpellLike';
import { breakpoints } from '../../styles/variables';
import { PRICING_DATA_LTD } from "../../data/pricing";
import { TopBar } from '../TopBar/TopBar';
import { useLocation } from '@reach/router';
import './stylev2.css'


const WORDS = ['ideas', 'flows', 'processes', 'plans', 'concepts', 'problems'];


export const DealIntroNewV2Sale = ( ): ReactElement => {
  const illustrationsRef = useRef(null);
  const { isVisible } = useVisibilityDetection(illustrationsRef);
  const pricing = {PRICING_DATA_LTD}.PRICING_DATA_LTD
  const [ showEIP, setShowEIP ] = useState(false)
  const months = [
    "January", "February", 
    "March", "April", "May", 
    "June", "July", "August",
    "September", "October", 
    "November", "December"
];
    const d = new Date().getMonth()
   const month = months[  d ]

   const location = useLocation();
  useEffect(() => {
    //console.log(showEIP)
    if(showEIP){
        //alert('show')
        document.querySelector('.exit-intent-popup').classList.add('visible');
        setShowEIP(false)
    }

    const deal_qstr =  window.location.href.split('?')[1];
    let links = document.getElementsByClassName("deal-cart-link");

    //let link = "https://checkout.sketchwow.com?add-to-cart=407";
    // if(location.pathname == '/sale/' || location.pathname == '/sale')
    //   link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1";
    
    let link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1";
    if(location.pathname == '/sale/' || location.pathname == '/sale')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=sl"; //link = "#DealPricing"; //
    else if(location.pathname == '/save/' || location.pathname == '/save')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=sv";//link = "#DealPricing"; //
    else if(location.pathname == '/offer/' || location.pathname == '/offer')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=of";//link = "#DealPricing"; //
    else if(location.pathname == '/lifetime/' || location.pathname == '/lifetime')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=lt";//link = "#DealPricing"; //
    else if(location.pathname == '/lifetime-deal/' || location.pathname == '/lifetime-deal')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=ltd";//link = "#DealPricing"; //
    else if(location.pathname == '/deal/' || location.pathname == '/deal')
       link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=dl";//link = "#DealPricing"; //
    else if((location.pathname == '/promo/' || location.pathname == '/promo') && localStorage.getItem('refid'))
      link = "https://checkout.sketchwow.com/?add-to-cart=407&cg2=1&swp=pr&coupon_code=save10percent&ref="+localStorage.getItem('refid');//link = "#DealPricing"; //
    else if(location.pathname == '/promo/' || location.pathname == '/promo')
      link = "https://checkout.sketchwow.com?add-to-cart=407&cg2=1&swp=pr";//link = "#DealPricing"; //
    // else if(location.pathname == '/sale-test/' || location.pathname == '/sale-test')
    //   link = "#DealPricing";

    if(typeof deal_qstr !== 'undefined' &&  links !== null){
      let i;
      for (i = 0; i < links.length; i++) {
        links[i].href = link + '&'  + deal_qstr;
      }
    }else if(links !== null){
      let i;
      for (i = 0; i < links.length; i++) {
        links[i].href = link;
      }
    }

    const script = document.createElement('script');
    script.src = 'https://embed.voomly.com/embed/embed-build.js';
    script.async=true;
    document.body.appendChild(script);

   }, [showEIP]);

 

  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper ref={illustrationsRef} isVisible={isVisible}>
        <Styled.Section>
          <Styled.Content>
            <TopBar />
            <Styled.Heading>
              <Styled.Title showAI={  true  }>
              Create fast, super-engaging simple sketches to clearly visualize
your{' '}
                <span
                  css={css`
                    display: inline-block;
                    position: relative;
                    z-index: 10;

                    @media screen and (max-width: ${breakpoints.md}px) {
                      margin-right: 0;
                    }
                  `}
                >
                  <SpellLike disabled={!isVisible} minWidth={130} words={WORDS} />
                </span>
              </Styled.Title>
                  


              {(location.pathname != '/' && location.pathname != '') && 
<div>
              <Styled.ArrowLeft/>

              <Styled.ArrowRight/>
              </div>
              }


              <Styled.SubTitle>And look like a HERO in front of your... clients, colleagues, community or class...</Styled.SubTitle>
            </Styled.Heading>
           
            {(location.pathname == '/' || location.pathname == '') &&
              <Styled.ButtonHome href="https://sketchwow.com/pricing">Get SketchWow</Styled.ButtonHome>
}
            {(location.pathname != '/' && location.pathname != '') && 
<div>

          <div id="mobile-displayNew">
            
               <div className="voomly-embed" data-id="s8j4zIeSRETHGHoCVM4TrRzSKstUAyjJq9ddD5sWRKJLFgBUA" data-ratio="1.777778" data-type="v"></div>
 
 

 

            <Styled.Badge css={css`z-index:11; position: relative;`}> </Styled.Badge>
            
            <div className="row-new-deal" css={css`height: 150px ; position: relative; z-index:10;`}>


                <div className='column-1' css={css`width: 72% !important;`}>
                <h3>Get SketchWow for a 1-Time Price</h3>
                <div>ZERO MONTHLY / ANNUAL FEES FOREVER</div>
                </div>
                    
                <div className='column-2' css={css`width: 22% !important;`}>
                  <h2 id="price-crossout"
                  css={css`font-size: 13px; color:#fff; margin-top:13px;`}
                  >$468/YEAR</h2>

                  <h1 css={css`color:#ECBE23 !important; margin-top:0px;`}>NOW: {pricing[0].std}</h1>
                </div>

                  {/*(onClick={()=>setShowEIP(true)} href="javascript:void(0);"*/}
                <Styled.Button  className="deal-cart-link" href="https://checkout.sketchwow.com?add-to-cart=407&cg2=1">Get SketchWow</Styled.Button>
                <br/>
                <br/>
                <Styled.AppInfo>Available For Windows & Mac computers</Styled.AppInfo>
            </div>
            <div className="row-new-deal">         
                <div className='column-1'>
                
                </div>
            </div>    
          </div>

          <div id="desktop-displayDSNew">
            <div id="desktop-displayNewv2">
              <Styled.HeartLeft/>
              <Styled.FireRight/>
                <div className="voomly-embed" data-id="s8j4zIeSRETHGHoCVM4TrRzSKstUAyjJq9ddD5sWRKJLFgBUA" data-ratio="1.777778" data-type="v"></div>
            </div>
 

            <Styled.HandLeft/>
            <Styled.HandRight/>
 
            <div className="row-new-deal">
                <div className='column-1'>
                <h3>Get SketchWow for a 1-Time Price</h3>
                <div>ZERO MONTHLY / ANNUAL FEES FOREVER</div>
                </div>

                <div className='column-2'>
                  <h2 id="price-crossout"
                  css={css`font-size: 15px; color:#fff; margin-top:13px;`}
                  >$468/YEAR</h2>

                  <h1 css={css`color:#ECBE23 !important; margin-top:5px;`}>NOW: {pricing[0].std}</h1>
                </div>
              

                <div className='column-3'>
                  {/*(onClick={()=>setShowEIP(true)} href="javascript:void(0);"*/}
                <Styled.Button  className="deal-cart-link" href="https://checkout.sketchwow.com?add-to-cart=407&cg2=1">Get SketchWow</Styled.Button>
                </div>
            </div>        

            <div className='badgeContainer' >
              
              <Styled.Badge> </Styled.Badge>
            
              </div>  
    
              <Styled.AppInfo className="availableFor">Available For Windows & Mac computers</Styled.AppInfo>

            </div>

</div>

}        
          </Styled.Content>



        </Styled.Section>
      </Styled.Wrapper>

    </Styled.SafariWrapper>
  );
};
