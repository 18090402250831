import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import Dropdown from '../../assets/icon-arrow.inline.svg';
import topBackground from '../../assets/backgrounds/navy-blue-top.svg';
//import bottomBackground from '../../assets/backgrounds/navy-blue-bottom.svg';
import bottomBackground from '../../../static/salespage/8th section/bottom-beige-path-coverup.svg';
import optionBanner from '../../../static/salespage/7th section/option-1.svg';
import { Visibility } from '../../types';

const TOP_BACKGROUND_HEIGHT = '1.25vw';
const BOTTOM_BACKGROUND_HEIGHT = '1.25vw';
 

export const SafariWrapper = styled.div`
  max-width: 100%;
  margin-bottom: -${BOTTOM_BACKGROUND_HEIGHT};
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  overflow: hidden;

`;

export const Wrapper = styled.section<Visibility>`
  opacity: 1;
  //margin-top: -16px;
  padding: 78px ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;
  background-color: #eae6de;
  color: ${colors.black};
  //transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
margin-top:50px;

  &:before,
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
  }

  &:before {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
    height: 80px;
    top: -65px;
    background: url('/salespage/9th section/bottom-beige-path-coverup.svg') no-repeat center 0;
    background-size: 100% 100px;

    @media screen and (min-width: 2300px) {
      background-size: 100% 140px;
    }
    
    @media screen and (max-width: ${breakpoints.xl}px) {
      top: -60px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      top: -54px;
    }
  }

  &:after {
    height: 120px;
    bottom: -92px;
    background: url(${bottomBackground}) no-repeat center 0;
    background-size: 100%;

    @media screen and (max-width: ${breakpoints.xl}px) {
      bottom: -110px;
    }

    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.02);
      
    }

    @media screen and (max-width: ${breakpoints.sm}px) {
      bottom: -139px;
      height: 140px;
    }
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    padding-top: 40px;
    padding-bottom: 40px;
  }
`;

export const Content = styled.div`
  padding-bottom: 6px;
  position: relative;
  z-index: 4;
  align-items: center;
  display: flex;
  max-width: 800px;
  margin: auto;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 0px;
    align-items: center;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    max-width: 100%;  margin-top: 0px;
  }
`;

export const Heading = styled.header`
  text-align: center;
  margin:auto;
  width: 800px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 100%;
  }
 

  img{
    padding: 20px;
    width: 500px;
    @media screen and (max-width: ${breakpoints.sm}px) {
      width: 90%;
    }
    
  }
`;

export const Title = styled.h2`
  margin: 0;
  color: currentColor;
  font-size: 38px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: 38px;
  }
`;

export const HeadingTitle = styled.h2`
  margin: 0;
  color: ${colors.darkNavyBlue};
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 700;
  line-height: ${dimensions.lineHeight.heading};
  width: 800px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
    width:100%;
    margin-bottom:20px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size: 24px;
  }
`;

export const AttentionSpan = styled.h3`
  margin: 0;
  color: currentColor;
 
    font-size: ${dimensions.fontSize.medium}px;
 
 
  text-align: left;
  line-height: ${dimensions.lineHeight.heading};
  font-weight: 200;

  &:before {
    content: '';
    background: url("/salespage/10th section/Group 4407.png"); background-repeat: no-repeat;
    margin-left: -200px;
    margin-top: -50px;
    float: left;
    width: 150px;
    height: 150px;
    background-size: contain;
    @media screen and (max-width: ${breakpoints.xl}px) {
      margin-left: -100px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      width: 100px;position:absolute;
      background-size:100%; margin-left: -30px;margin-top: -100px;
    }
    @media screen and (max-width: ${breakpoints.sm}px) {
      width: 80px;
      background-size:80%;margin-top: -70px;
    }
  }

  @media screen and (max-width: ${breakpoints.sm}px) {
    //font-size: ${dimensions.fontSize.heading * 0.7}px;
    font-size:18px;
  }
`;

export const SubTitle = styled.h3`
  margin: 0;
  color: currentColor;
  font-size: ${dimensions.fontSize.large}px;
  text-align: left;
  line-height: ${dimensions.lineHeight.heading};
  font-weight: 600;


  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size:18px;
  }
`;

export const MiniSub = styled.h5`
  margin: auto;
  color: currentColor;

 
    font-size: ${dimensions.fontSize.medium}px;
 
 
  font-weight: 200;
  line-height: ${dimensions.lineHeight.heading};
  margin-top: 20px;

  @media screen and (max-width: ${breakpoints.sm}px) {
    //font-size: ${dimensions.fontSize.heading * 0.7}px;
    font-size:18px;
  }
`;

export const Section = styled.section`
  display: grid;
  max-width: 900px;
  width: 100%;
  margin: 34px auto 0 auto;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 27px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 0;
    grid-template-columns: 1fr;
  }
`;

export const LeftColumn = styled.div`
  margin-left: 0px;
  
`;


export const RightColumn = styled.div`
  margin-left: 0px;
`;

export const Illustration = styled.img`
  transform: translate(30px, -2px);
  width: 520px;
  @media screen and (max-width: ${breakpoints.lg}px) {
    transform: none;
    width:90%;
  }
`;

export const TextEnder = styled.div`
line-height: ${dimensions.lineHeight.heading};
  max-width: 840px;
  margin: auto;
 
    font-size: ${dimensions.fontSize.medium}px;
 
 
  font-weight: 200;
  padding-bottom: 10px;
  border-bottom: 1px solid #99A98F;
  @media screen and (max-width: ${breakpoints.sm}px) {
    //font-size: ${dimensions.fontSize.heading * 0.7}px;
    font-size:18px;
  }
`;

