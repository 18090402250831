import React, { ReactElement, useRef } from 'react';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import * as Styled from './styles';
import { css } from '@emotion/react';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { breakpoints } from '../../styles/variables';
import { useLocation } from '@reach/router';

export const EyeCatchingTemplates = (): ReactElement => {
  const eyeCatchingRef = useRef(null);
  const { isVisible } = useVisibilityDetection(eyeCatchingRef);
  const location = useLocation();
 

  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper ref={eyeCatchingRef} isVisible={isVisible}>
      <Styled.Section>
          <Styled.Content>

      <TwoColSection
        styles={css`
          
          max-width: 1170px;
          margin-top: 118px;

         /* opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/
            

          @media screen and (max-width: ${breakpoints.lg}px) {
            margin-top: 80px;
          }
          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
          }


        `}

        heading={
          <div css={css`
         
          @media screen and (max-width: ${breakpoints.lg}px) {
            margin-bottom: 0px;  
          }
        `}>
          <Styled.Heading >
            <Styled.HeadingTitle>Whenever You Want To Diagram, Explain, Present, Or Map Out... SketchWow Has An Eye-Catching Template For You</Styled.HeadingTitle>
            <Styled.SubTitle >Nobody creates only one type of diagram or visual. That's why we loaded SketchWow with dozens and dozens of templates in a wide variety of categories. So you can use 
              SketchWow to create all sorts of unique visuals, including:</Styled.SubTitle>
          </Styled.Heading>
          </div>
        }

        leftColumn={
          <Styled.LeftColumn> 
              <Styled.IconContainer>

                <Styled.Icons>
                  <img  src="/salespage/6th section/diagrams.svg"  alt="diagrams" loading="lazy" />
                  <br/>
                  <small>Diagrams</small>
                </Styled.Icons>
                <Styled.Icons>
                  <img  src="/salespage/6th section/lessons.svg"  alt="lessons" loading="lazy" />
                  <br/>
                  <small>Lessons</small>
                </Styled.Icons>
                <Styled.Icons>
                  <img  src="/salespage/6th section/handouts.svg"  alt="handouts" loading="lazy" />
                  <br/>
                  <small>Handouts</small>
                </Styled.Icons>
                
                <Styled.Icons>
                  <img  src="/salespage/6th section/network-diagrams.svg"  alt="network diagrams" loading="lazy" />
                  <br/>
                  <small>Network Diagrams</small>
                </Styled.Icons>
                
                
                <Styled.Icons>
                  <img  src="/salespage/6th section/workflows.svg"  alt="workflows" loading="lazy" />
                  <br/>
                  <small>Workflows</small>
                </Styled.Icons>

               </Styled.IconContainer>
               <Styled.IconContainer>
                <Styled.Icons>
                  <img  src="/salespage/6th section/presentations.svg"  alt="presentations" loading="lazy" />
                  <br/>
                  <small>Presentations</small>
                </Styled.Icons>
                
                <Styled.Icons>
                  <img  src="/salespage/6th section/charts.svg"  alt="charts" loading="lazy" />
                  <br/>
                  <small>Charts</small>
                </Styled.Icons>
                
                <Styled.Icons>
                  <img  src="/salespage/6th section/social-posts.svg"  alt="social-posts" loading="lazy" />
                  <br/>
                  <small>Social Posts</small>
                </Styled.Icons>
               
                <Styled.Icons>
                  <img  src="/salespage/6th section/visual-notes.svg"  alt="visual-notes" loading="lazy" />
                  <br/>
                  <small>Visual Notes</small>
                </Styled.Icons>
                
                <Styled.Icons>
                  <img  src="/salespage/6th section/mind-maps.svg"  alt="mind-maps" loading="lazy" />
                  <br/>
                  <small>Mind Maps</small>
                </Styled.Icons>
                </Styled.IconContainer>
                <Styled.IconContainer>
                <Styled.Icons>
                  <img  src="/salespage/6th section/graphs.svg"  alt="graphs" loading="lazy" />
                  <br/>
                  <small>Graphs</small>
                </Styled.Icons>
                
                <Styled.Icons>
                  <img  src="/salespage/6th section/timelines.svg"  alt="timelines" loading="lazy" />
                  <br/>
                  <small>Timelines</small>
                </Styled.Icons>
                
                <Styled.Icons>
                  <img  src="/salespage/6th section/venn-diagrams.svg"  alt="venn-diagrams" loading="lazy" />
                  <br/>
                  <small>Venn Diagrams</small>
                </Styled.Icons>
                
                <Styled.Icons>
                  <img  src="/salespage/6th section/process-maps.svg"  alt="process-maps" loading="lazy" />
                  <br/>
                  <small>Process Maps</small>
                </Styled.Icons>
               
                 <Styled.Icons>
                  <img  src="/salespage/6th section/infographics.svg"  alt="infographics" loading="lazy" />
                  <br/>
                  <small>Infographics</small>
                </Styled.Icons>
             
                  </Styled.IconContainer>
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>
            
            <Styled.Illustration
                src= "/salespage/6th section/SW-screenshot-1.png"
                alt="Illustration"
                loading="lazy"
                css={css`
                @media screen and (max-width: ${breakpoints.xxl}px) {
                  margin-left: 0; width:100%;
                }                
              `}
              />
          </Styled.RightColumn>
        }
      />


      <TwoColSection
        styles={css`
          
          max-width: 1170px;
          margin-top: 0px;

          /* opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/

          margin-top: -20px;
          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
          }

         
        `}

        leftColumn={
          <Styled.LeftColumn> 
            <Styled.Illustration
                src="/salespage/6th section/SW-screenshot-2.png"
                alt="Illustration"
                loading="lazy"
                css={css`
                  margin-left: -158px;
                  @media screen and (max-width: ${breakpoints.xxl}px) {
                    margin-left: 0; width:100%;
                  }
                  @media screen and (max-width: ${breakpoints.xl}px) {
                    margin-left: -45px;
                  }
                  
                `}
              />
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>
            
            <Styled.IconContainer>

                <Styled.Icons>
                  <img  src="/salespage/6th section/animated-diagrams.svg"  alt="animated-diagrams" loading="lazy" />
                  <br/>
                  <small>Animated Diagrams</small>
                </Styled.Icons>
                <Styled.Icons>
                  <img  src="/salespage/6th section/diagrams-1.svg"  alt="diagrams-1" loading="lazy" />
                  <br/>
                  <small>Journeys</small>
                </Styled.Icons>

                <Styled.Icons>
                  <img  src="/salespage/6th section/empathy-maps.svg"  alt="empathy-maps" loading="lazy" />
                  <br/>
                  <small>Empathy Maps</small>
                </Styled.Icons>

                <Styled.Icons>
                  <img  src="/salespage/6th section/funnel-maps.svg"  alt="funnel-maps" loading="lazy" />
                  <br/>
                  <small>Funnel Maps</small>
                </Styled.Icons>


                <Styled.Icons>
                  <img  src="/salespage/6th section/visual-contents.svg"  alt="visual-contents" loading="lazy" />
                  <br/>
                  <small>Visual Content</small>
                </Styled.Icons>

                </Styled.IconContainer>
                <Styled.IconContainer>
                <Styled.Icons>
                  <img  src="/salespage/6th section/meeting-summaries.svg"  alt="meeting-summaries" loading="lazy" />
                  <br/>
                  <small>Meeting Summaries</small>
                </Styled.Icons>

                <Styled.Icons>
                  <img  src="/salespage/6th section/ads.svg"  alt="ads" loading="lazy" />
                  <br/>
                  <small>Ads</small>
                </Styled.Icons>

                <Styled.Icons>
                  <img  src="/salespage/6th section/ebook-images.svg"  alt="ebook-images" loading="lazy" />
                  <br/>
                  <small>Ebook Images</small>
                </Styled.Icons>

                <Styled.Icons>
                  <img  src="/salespage/6th section/prototypes.svg"  alt="prototypes" loading="lazy" />
                  <br/>
                  <small>Prototypes</small>
                </Styled.Icons>

                <Styled.Icons>
                  <img  src="/salespage/6th section/video-thumbnails.svg"  alt="video-thumbnails" loading="lazy" />
                  <br/>
                  <small>Video Thumbnails</small>
                </Styled.Icons>
                </Styled.IconContainer>
                <Styled.IconContainer>
                <Styled.Icons>
                  <img  src="/salespage/6th section/lo-fi-wireframes.svg"  alt="lo-fi-wireframes" loading="lazy" />
                  <br/>
                  <small>Lo-fi Wireframes</small>
                </Styled.Icons>

                <Styled.Icons>
                  <img  src="/salespage/6th section/mockups.svg"  alt="mockups" loading="lazy" />
                  <br/>
                  <small>Mockups</small>
                </Styled.Icons>

                <Styled.Icons>
                  <img  src="/salespage/6th section/brainstorms.svg"  alt="brainstorms" loading="lazy" />
                  <br/>
                  <small>Brainstorms</small>
                </Styled.Icons>

                <Styled.Icons>
                  <img  src="/salespage/6th section/annotations.svg"  alt="annotations" loading="lazy" />
                  <br/>
                  <small>Annotations</small>
                </Styled.Icons>

                <Styled.Icons>
                  <img  src="/salespage/6th section/And more!.svg" css={css`width:120px; margin-top: 20px;`}  alt="And more!" loading="lazy" />
                  <br/>
                  <small></small>
                </Styled.Icons>

                  </Styled.IconContainer>

          </Styled.RightColumn>
        }
      />

      </Styled.Content>
      </Styled.Section>
    </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};











