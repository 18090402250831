import React, { ReactElement, useRef } from 'react';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import * as Styled from './styles';
import { css } from '@emotion/react';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { breakpoints } from '../../styles/variables';
import { Feature } from "../Feature/Feature";
import { FeatureParagraphCrown, FeatureParagraphMark, FeatureTitleSwirl,} from "../../styles/styles";
import LogoIllustration from '../../assets/logo.inline.svg';
import { useLocation } from '@reach/router';

export const SketchEffect = (): ReactElement => {
  const sketchEffectRef = useRef(null);
  const { isVisible } = useVisibilityDetection(sketchEffectRef);
  const location = useLocation(); 


  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper ref={sketchEffectRef} isVisible={isVisible}>
      <TwoColSection
        styles={css`
          
          max-width: 1170px;
          margin-top: 0px;

          /* opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
              opacity: 1;
              transform: translateY(0);
            `}*/

          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 0px;
          }

          
        `}

        heading={
          <Styled.Heading  css={css`
                                margin: auto;
                              `}>
            <Styled.HeadingTitle>Keep It Traditional Or Use A Sketch-Effect?</Styled.HeadingTitle>
          </Styled.Heading>
        }

        leftColumn={
          <Styled.LeftColumn css={css`
          margin-top: -150px;
          @media screen and (max-width: ${breakpoints.lg}px) {
            margin-top: 0px;
            margin-left:0;padding-right:0;
          }
        `}>
              
              <Styled.Content>
              <p>With SketchWow you can create more "classic" looking diagrams. Or spice things up with a hand drawn sketch look. It's your call.</p>
              <p>Depending on your audience, use case, client, project or job requirements... SketchWow has you covered.</p>
            </Styled.Content>
            
          </Styled.LeftColumn>
        }
        rightColumn={
          <Styled.RightColumn>
            <Styled.Illustration
                src="/salespage/9th section/diagramming-classy-sassy.png"
                alt="Illustration"
                loading="lazy"
              />

          </Styled.RightColumn>
        }
      />
      <br/>
      <Styled.CheckListWrapper>
        <Styled.CheckListTitle>Or use SketchWow for fun stuff like mapping out your family tree. You'll never <Styled.FeatureParagraphMarkLong>run out of ways to use</Styled.FeatureParagraphMarkLong> it.</Styled.CheckListTitle>

        <Styled.CheckListContainerBlue >
        <Styled.CheckListSubTitle>Add your SketchWow designs to:</Styled.CheckListSubTitle>
          <Styled.CheckListBlue >eBooks (Physical Books)</Styled.CheckListBlue>
          <Styled.CheckListBlue >Videos</Styled.CheckListBlue>
          <Styled.CheckListBlue >Client Proposals</Styled.CheckListBlue>
          <Styled.CheckListBlue >Courses</Styled.CheckListBlue>
          <Styled.CheckListBlue >Training or Lectures</Styled.CheckListBlue>
          <Styled.CheckListBlue >Marketing Materials</Styled.CheckListBlue>
          <Styled.CheckListBlue >Lead Magnets</Styled.CheckListBlue>
          <Styled.CheckListBlue >Social Posts</Styled.CheckListBlue>
          <Styled.CheckListBlue >Internal Memos</Styled.CheckListBlue>
          <Styled.CheckListBlue >Blogs or Websites</Styled.CheckListBlue>
          <Styled.CheckListBlue >Presentations</Styled.CheckListBlue>
          <Styled.CheckListBlue >Emails</Styled.CheckListBlue>

        </Styled.CheckListContainerBlue>


      </Styled.CheckListWrapper>


      <Styled.CheckListWrapper>
      <Styled.CheckListTitleWhite>Next Time... Reach for SketchWow</Styled.CheckListTitleWhite> 

        <Styled.CheckListContainer>
          <Styled.CheckList>Brainstorm, Visually Explain Anything...</Styled.CheckList>
          <Styled.CheckList ><div  >Markup, Annotate...</div></Styled.CheckList>
          <Styled.CheckList>Scroll Stopping Social Posts, Blog Graphics, Charts & Animations...</Styled.CheckList>
          <Styled.CheckList>Say "See-Ya" to Snooze-Fest Slide Decks...</Styled.CheckList>
          <Styled.CheckList>Newsletters, Hand Outs, Advertisements, Lesson Plans, Technical Docs & More!</Styled.CheckList>
          <Styled.CheckList>Makes white-boreds & zzzzz-Zoom calls way more engaging too!</Styled.CheckList>
        </Styled.CheckListContainer>


      </Styled.CheckListWrapper>

    </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};











