import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import { Visibility } from "../../types";

const TOP_BACKGROUND_HEIGHT = "1.45vw";
const BOTTOM_BACKGROUND_HEIGHT = "1.88vw";

 

export const SafariWrapper = styled.div`
  width: 100%;
  overflow: hidden;
  background-color: #FEDA53;
  margin-top:-1px;
`;

export const Wrapper = styled.section<Visibility>`
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  margin-top: ${TOP_BACKGROUND_HEIGHT};
  margin-bottom: calc(1px + ${BOTTOM_BACKGROUND_HEIGHT});
  // padding: 0 ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;
  align-items: center;
  text-align: center;
  justify-content: center;
 
  @media screen and (max-width: ${breakpoints.lg}px) {
    height: auto;
    margin-bottom: calc(60px + ${BOTTOM_BACKGROUND_HEIGHT});
  }

  @media screen and (max-width: ${breakpoints.xl}px) {
    margin-top: 5vw;
  }
  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-bottom: 50px;
  }
  @media screen and (max-width: ${breakpoints.md}px) {
    margin-top: 0vw;
    margin-bottom: 30px;
  }

  &:after {
    content: "";
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
    height: 200px;
    bottom: -180px;
    background: url('/salespage/1st section/bottom-white-path-coverup.svg') no-repeat center 0;
    background-size: 100% 100px;

    @media screen and (min-width: 2300px) {
      bottom: -220px;
    }
    
    @media screen and (max-width: ${breakpoints.xl}px) {
      height: 118px;
      bottom: -80px;
    }
    @media screen and (max-width: ${breakpoints.lg}px) {
      transform: scale(1.1);
      bottom: -120px;
    }
    @media screen and (max-width: ${breakpoints.md}px) {
      bottom: -96px;
    }
  }
`;

export const Section = styled.section`

  max-width: 1110px;
  width: 100%;
  margin: 0 auto;
  position: relative;
 
  align-items: center;
  box-sizing: border-box;
  @media screen and (max-width: ${breakpoints.lg}px) {
      padding-left:30px;padding-right:30px;
  }

  @media screen and (min-width: 1024px) and (max-width: ${breakpoints.lg}px) {
    padding-top: 80px;
    padding-bottom: 80px;

  }

  @media screen and (max-width: ${breakpoints.md}px) {
    padding-top: 40px;
    padding-bottom: 0px;
  }


`;

export const Content = styled.div`
  padding-bottom: 6px;
  position: relative;
  z-index: 4;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 0px;
    align-items: center;
  }

  @media screen and (max-width: ${breakpoints.md}px) {
    max-width: 100%;
  }
`;


export const Heading = styled.header`
  width: 100%;
  margin-bottom: 33px;
  text-align: center;
  margin: auto;
`;

export const HeadingTitle = styled.h2`
  margin: 0;
  font-size: ${dimensions.fontSize.heading}px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: ${dimensions.fontSize.heading * 0.7}px;
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size:24px;
  }
`;

export const SubTitle = styled.h3`
  margin: 0 auto 19px auto;
  font-weight: 200;
  font-size: ${dimensions.fontSize.medium}px;
  line-height: ${dimensions.lineHeight.large};
  width: 700px;
  margin-top:20px;
  color:#000;
  
  @media screen and (max-width: ${breakpoints.lg}px) {
    width: 100%;   
  }
  @media screen and (max-width: ${breakpoints.sm}px) {
    font-size:18px;
 
        font-size:16px;
 
  }
`;

export const Visuals = styled.div`
  width: 929px;
  height: 929px;
  margin: auto;
  @media screen and (max-width: ${breakpoints.lg}px) {
    width:95%;
    height:auto;
    img{max-width:95%;}

  }
`;
