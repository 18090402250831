import React, { ReactElement, Fragment, useMemo, useRef, useState,useEffect } from 'react';
import { css } from '@emotion/react';
import { TwoColSection } from '../../components/TwoColSection/TwoColSection';
import * as Styled from './styles1';
import { breakpoints } from '../../styles/variables';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import styled from 'styled-components';
import { useLocation } from '@reach/router';

export const OptionOne = (): ReactElement => {

  const optionOneRef = useRef(null);
  const { isVisible } = useVisibilityDetection(optionOneRef);
  const location = useLocation(); 
  const [ activeItem, setActiveItem ] = useState("/salespage/7th section/option-1 step-1.png")

  
  useEffect(() => {
    // /console.log('activeItem', activeItem)
  }, [activeItem])
  return (

    <Styled.SafariWrapper >
      <Styled.Wrapper ref={optionOneRef} isVisible={isVisible}>
        <Styled.OptionBanner><img src="/salespage/7th section/option-1.svg" /></Styled.OptionBanner>
        <Styled.Heading>
          <Styled.Title>Not a Designer? Not a Problem!</Styled.Title>
          <Styled.SubTitle>SketchWow Makes It Super-Simple To Create Eye-Catching Designs...</Styled.SubTitle>
          <Styled.SubTitle>Even For Non-Creative Types With Zero Tech Skills</Styled.SubTitle>
          <Styled.MiniSubTitle>Pick a Template. Crank-out a clear, catchy visual in 4 simple steps!</Styled.MiniSubTitle>
        </Styled.Heading>

        <Styled.Section css={css`
         @media screen and (max-width: ${breakpoints.lg}px) {
          display:none
         }
        `}>

          <TwoColSection
            styles={css`
          
          max-width: 1170px;
          margin-top: -150px;

          /* opacity: 0;
          transform: translateY(10%);
          transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
          ${isVisible &&
            `
          opacity: 1;
          transform: translateY(0);
         `}*/

          @media screen and (max-width: ${breakpoints.md}px) {
            margin-top: 40px;
            padding:0;
          }

          
        `}

            heading={
              <div css={css`
          margin-bottom: 158px;
          @media screen and (max-width: ${breakpoints.md}px) {
            margin-bottom: 0px;
          }
          
        `}>
              </div>
            }

            leftColumn={
              
              <Styled.LeftColumn>
                <Styled.List onClick={()=>setActiveItem('/salespage/7th section/option-1 step-1.png')} 
                  className={`${activeItem==='/salespage/7th section/option-1 step-1.png' && 'active'}`}>
                  <Styled.Number css={css`background: rgba(255, 255, 255, 0.2);`}>1</Styled.Number> Click On Templates</Styled.List>
              
                <Styled.List onClick={()=>setActiveItem('/salespage/7th section/option-1 step-2.png')}
                className={`${activeItem==='/salespage/7th section/option-1 step-2.png' && 'active'} second`}>
                  <Styled.Number css={css`background: rgba(255, 255, 255, 0.2);`}>2</Styled.Number> <div className='text'>Choose a Template category <br /> (or browse them all)</div>
                </Styled.List>
                <Styled.List onClick={()=>setActiveItem('/salespage/7th section/option-1 step-3.png')}
                className={`${activeItem==='/salespage/7th section/option-1 step-3.png' && 'active'} third`}>
                  <Styled.Number css={css`background: rgba(255, 255, 255, 0.1);`}>3</Styled.Number>
                  <div className='text'>Pick a Template that catches <br />your eye (or copy/paste part of it <br />into a new Document)</div>
              </Styled.List>
                <Styled.List  onClick={()=>setActiveItem('/salespage/7th section/option-1 step-4.png')}
                className={`${activeItem==='/salespage/7th section/option-1 step-4.png' && 'active'} fourth`}>
                <Styled.Number css={css`background: rgba(255, 255, 255, 0.1);`}>4</Styled.Number>
                <div className='text'> Edit, Personalize. Choose from <br /> hundreds of icons. Done!</div>
              </Styled.List>
              </Styled.LeftColumn>
            } 

            rightColumn={
              <Styled.RightColumn>

                <Styled.Whiteboard>
                  <img src={activeItem} />
                </Styled.Whiteboard>
              </Styled.RightColumn>
            }
          />

        </Styled.Section>



        <Styled.Section css={css`
         @media screen and (min-width: 993px) {
          display:none
         }
        `}>
          <div>
            <Styled.ListMob >
              <Styled.NumberMob>1</Styled.NumberMob>
              <Styled.Cont>Click On Templates</Styled.Cont>
            </Styled.ListMob>

            <Styled.Whiteboard>
              <img src="/salespage/7th section/option-1 step-1.png" />
            </Styled.Whiteboard>
          </div>

          <div>
            <Styled.ListMob >
              <Styled.NumberMob>2</Styled.NumberMob>
              <Styled.Cont>Choose a Template category (or browse them all)</Styled.Cont>
            </Styled.ListMob>


            <Styled.Whiteboard>
              <img src="/salespage/7th section/option-1 step-2.png" />
            </Styled.Whiteboard>
          </div>

          <div>
            <Styled.ListMob >
              <Styled.NumberMob>3</Styled.NumberMob>
              <Styled.Cont><div className='text'>Pick a Template that catches your eye (or copy/paste part of it into a new Document)</div></Styled.Cont>
            </Styled.ListMob>

            <Styled.Whiteboard>
              <img src="/salespage/7th section/option-1 step-3.png" />
            </Styled.Whiteboard>
          </div>

          <div>
            <Styled.ListMob >
              <Styled.NumberMob>4</Styled.NumberMob>
              <Styled.Cont>Edit, Personalize. Choose from hundreds of icons. Done!</Styled.Cont>
              </Styled.ListMob>

            <Styled.Whiteboard>
              <img src="/salespage/7th section/option-1 step-4.png" />
            </Styled.Whiteboard>

          </div>

        </Styled.Section>




        <Styled.Steps >
          <div>Final step? In 2 clicks... Copy-and-paste your design into your favorite apps. Or Export to JPEG, SVG, PNG, transparent PNG, PDF or animated GIF. </div>
          <div>Within a few minutes, you'll have a unique diagram, content, process map, workflow or lesson to share with your audience. </div>
          <div>Drop you new snazzy visual into an ebook, course, info product, document, blog post, webpage and more. </div>
        </Styled.Steps>

      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};
