import styled from '@emotion/styled';
import { breakpoints, colors, dimensions } from '../../styles/variables';
import Dropdown from '../../assets/icon-arrow.inline.svg';
import topBackground from '../../assets/backgrounds/navy-blue-top.svg';
//import bottomBackground from '../../assets/backgrounds/navy-blue-bottom.svg';
import bottomBackground from '../../../static/salespage/8th section/bottom-beige-path-coverup.svg';
import optionBanner from '../../../static/salespage/7th section/option-1.svg';
import { Visibility } from '../../types';
import "../../../global.css"
const TOP_BACKGROUND_HEIGHT = '1.25vw';
const BOTTOM_BACKGROUND_HEIGHT = '1.25vw';

 


export const SafariWrapper = styled.div`
  max-width: 100%;
  margin-bottom: -${BOTTOM_BACKGROUND_HEIGHT};
  padding-bottom: ${BOTTOM_BACKGROUND_HEIGHT};
  overflow: hidden;
  margin-top: -60px;
`;

export const Wrapper = styled.section<Visibility>`
  
  //margin-top: -16px;
  padding: 78px ${dimensions.containerPadding}px;
  position: relative;
  z-index: 2;
  background-color: #eae6de;
  color: ${colors.black};

  /*
  opacity: 0;
  transform: translateY(10%);
  transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;

  ${({ isVisible }) =>
    isVisible &&
    `
    opacity: 1;
    transform: translateY(0);
  `}
*/

  &:before,
  &:after {
    content: '';
    width: 100%;
    position: absolute;
    left: 0;
    background-size: contain;
  }

  &:after {
    height: 120px;
    bottom: -92px;
    background: url(${bottomBackground}) no-repeat center 0;
    background-size: 100%;
    @media screen and (max-width: ${breakpoints.lg}px) {
      //transform: scale(1.02);
      bottom: -133px;
      height: 140px;
    }

    @media screen and (max-width: ${breakpoints.sm}px) {
      bottom: -139px;
      height: 140px;
    }
  }

  @media screen and (max-width: ${breakpoints.lg}px) {
    padding-top: 80px;
    padding-bottom: 40px;
    .left .content{flex-direction:column;}
  }
`;

export const OptionBanner = styled.figure`
  width: 100%;
  text-align: center;
  margin :auto;
  margin-top: -50px;
  z-index: 10;
  position: absolute;
  
  @media screen and (max-width: ${breakpoints.md}px) {
    position: inherit;
    margin-top: -30px;
  }
  img{
    height: 80px;
    @media screen and (max-width: ${breakpoints.md}px) {
      width:80%;
      height:auto;
    }
    @media screen and (max-width: ${breakpoints.sm}px) {
      width:100%;
    }
  }



`;

export const Heading = styled.header`
  text-align: center;
`;

export const Title = styled.h2`
  margin: 0;
  color: currentColor;
  font-size: 38px;
  font-weight: 900;
  line-height: ${dimensions.lineHeight.heading};

  @media screen and (max-width: ${breakpoints.lg}px) {
    font-size: 38px;
  }
`;

export const SubTitle = styled.h3`
  margin: 0;
  color: currentColor;
  font-size: ${dimensions.fontSize.medium}px;
  font-weight: 400;
  font-family: 'Gochi Hand', cursive;
  line-height: ${dimensions.lineHeight.heading};


  @media screen and (max-width: ${breakpoints.lg}px) {
    // font-size: ${dimensions.fontSize.medium * 0.7}px;
    font-size:20px;
  }
`;

export const MiniSub = styled.h5`
  margin: auto;
  color: currentColor;
 
    font-size: ${dimensions.fontSize.medium}px;
 
 
  font-weight: 200;
  line-height: ${dimensions.lineHeight.heading};

  margin-top: 20px;

  @media screen and (max-width: ${breakpoints.sm}px) {
    // font-size: ${dimensions.fontSize.heading * 0.7}px;
    font-size:18px;
  }
`;

export const Section = styled.section`
  display: grid;
  max-width: ${1080 + dimensions.containerPadding}px;
  width: 100%;
  margin: 34px auto 0 auto;
  position: relative;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 27px;

  @media screen and (max-width: ${breakpoints.lg}px) {
    margin-top: 40px;
    grid-template-columns: 1fr;
  }
`;

export const LeftColumn = styled.div`
  margin-left: 0px;
  
`;


export const RightColumn = styled.div`
  margin-left: 0px;
  @media screen and (max-width: ${breakpoints.lg}px) {
  div.arrowDown{position:relative;}
  }
  div.arrowDown::after{
    display: block;
    content: '';
    height: 800px;
    width: 200px;
    background: url('/salespage/8th section/blue-arrow1.png') no-repeat center 0;
    background-size: contain;
    margin: auto;
    margin-top: -100px;
    right:-100px;
    z-index: 3;
    position: absolute;
    @media screen and (max-width: ${breakpoints.xl}px) {
      right:-50px;height:200px;

    }
       @media screen and (max-width: ${breakpoints.lg}px) {
      background-size: 100%;right:-120px;
    }
    @media screen and (max-width:${breakpoints.sm}px) {
      right:-100px; background-size: 80%;margin-top: -60px;
    }
    @media screen and (max-width:399px) {
      background-size: 55%
    }
    @media screen and (max-width:360px) {
      display:none;
    }

  }
`;



export const Illustration = styled.img`
  transform: translate(30px, -2px);

  @media screen and (max-width: ${breakpoints.sm}px) {
    transform: none;
  }
`;

