import React, { ReactElement, useRef } from 'react';
import * as Styled from './styles';
import { css } from '@emotion/react';
import { useVisibilityDetection } from '../../hooks/useVisibilityDetection';
import { useLocation } from '@reach/router';

export const VisualBenefits = (): ReactElement => {
  
  const visualsRef = useRef(null);
  const { isVisible } = useVisibilityDetection(visualsRef);
  const location = useLocation(); 

  return (
    <Styled.SafariWrapper>
      <Styled.Wrapper ref={visualsRef} isVisible={isVisible}>
        <Styled.Section>
          <Styled.Content>
            <Styled.Heading>
            <Styled.HeadingTitle>Get More Clicks, Likes, Shares & Boost Engagement & Retention Using Hand Drawn Style Designs</Styled.HeadingTitle>
            <Styled.SubTitle>According to some experts... using casual "hand drawn" style graphics (like what SketchWow creates) to explain, present or emphasize key points and ideas... offers several advantages, including:</Styled.SubTitle>
          </Styled.Heading>

          <Styled.Visuals>
            <img src= "/salespage/3rd section/BENEFITS OF HD VISUALSsw.png"/>
          </Styled.Visuals>

          </Styled.Content>
        </Styled.Section>
      </Styled.Wrapper>
    </Styled.SafariWrapper>
  );
};











